<template>
  <!--发布需求-->
  <van-tabs v-model="active" color="#1989fa">
    <van-tab title="招工"> <recruitment /> </van-tab>
    <van-tab title="需求"> <demand /> </van-tab>
  </van-tabs>
</template>

<script>
import demand from "./sub/demand.vue";
import recruitment from "./sub/recruitment.vue";
import store from "../../store";
import {Dialog} from "vant";
export default {
  data() {
    return {
      user:undefined,
      active: "招工",
    };
  },
  components: {
    demand,
    recruitment,
  },
  mounted() {
    this.user = JSON.parse(`${store.state.user}`)
    console.info("demand mounted",this.user,this.user.cardNo)
    if (this.user.cardNo){
      this.$router.push("demand");
    }else {
      Dialog.alert({
        title: '提醒',
        message: '发布招工信息需要您先注册成为老板，点击确定去注册',
      })
      .then(() => {
        this.$router.push("my-authentication");
      })

    }
  }
};
</script>